// welcome bar
.welcome-bar {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px 0;
}
.welcome-bar__display-text {
  color: $color-navy;
  font-size: 45px;
  font-weight: 400;
  line-height: 60px;
}
// left
.welcome-bar__left {
  display: flex;
  padding: 0 12px;
  width: 100%;
}
// right
.welcome-bar__right {
  display: flex;
  flex-direction: column;
  width: 100%;
}
// info left
.welcome-bar__info-left {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.welcome-bar__info-left-col {
  display: flex;
}
.welcome-bar__info-left-img {
  margin-left: 16px;
  width: 90px;
}
.welcome-bar__message {
  font-family: $font-avenir;
  font-weight: 400;
  letter-spacing: -0.55px;
}
.welcome-bar__label {
  align-items: center;
  background-color: $color-electric-blue;
  border-radius: 4px;
  color: $color-white;
  display: flex;
  font-size: 12px;
  height: 19px;
  justify-content: center;
  margin: 0 16px 0 0;
  width: 46px;
}
// info middle
.welcome-bar__info-middle {
  border-top: 1px solid #c7dbf2;
  border-bottom: 1px solid #c7dbf2;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.welcome-bar__info-middle-row {
  align-items: center;
  display: flex;
}
.welcome-bar__temp {
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: translateY(-4px);
}
.welcome-bar__forecast {
  text-align: right;
  width: 100%;
}
.welcome-bar__degrees {
  color: $color-electric-blue;
  font-size: 40px;
}
.welcome-bar__farenheit {
  font-size: 20px;
  font-weight: 500;
  line-height: 7px;
}
.welcome-bar__display-text--temp {
  margin: 0 4px 0 16px;
}
// info right
.welcome-bar__info-right {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.welcome-bar__info-right-row {
  align-items: center;
  display: flex;
}
.welcome-bar__pipe {
  margin: 0 8px;
}

@media (min-width: $breakpoint-tablet-landscape) {
  // welcome bar
  .welcome-bar {
    align-items: center;
    border-left: 8px solid $color-sunshine;
    box-shadow: 0 2px 6px 0 rgba(99, 129, 163, 0.17);
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 36px;
  }
  // left
  .welcome-bar__left {
    flex-direction: column;
    padding: 0;
    width: auto;
  }
  // right
  .welcome-bar__right {
    flex-direction: row;
    width: auto;
  }
  // info left
  .welcome-bar__info-left {
    padding: 0 12px;
  }
  .welcome-bar__info-left-col {
    flex-direction: column;
  }
  .welcome-bar__label {
    margin: 0 0 14px;
  }
  // info middle
  .welcome-bar__info-middle {
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #c7dbf2;
    border-right: 1px solid #c7dbf2;
    flex-direction: column;
    padding: 0 12px;
  }
  .welcome-bar__info-middle-icon {
    width: 44px;
  }
  // info right
  .welcome-bar__info-right {
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 12px;
  }
  .welcome-bar__info-right-row {
    display: flex;
    justify-content: flex-end;
  }

  // welcome bar compressed
  .welcome-bar-compressed {
    padding: 20px 48px;
    .welcome-bar__message {
      font-size: 24px;
      letter-spacing: -0.38px;
      line-height: 26px;
    }
    .welcome-bar__left {
      flex-direction: row;
    }
    // left
    .welcome-bar__info-left {
      padding: 0 36px 0 24px;
    }
    .welcome-bar__info-left-col {
      flex-direction: row;
    }
    .welcome-bar__label {
      margin: 0 16px 0 0;
    }
    .welcome-bar__info-left-img {
      width: 60px;
    }
    // middle
    .welcome-bar__info-middle {
      padding: 0 36px;
    }
    .welcome-bar__info-middle-icon {
      width: 38px;
    }
    // right
    .welcome-bar__info-right {
      padding: 0 0 0 36px;
    }
    .welcome-bar__display-text {
      font-size: 30px;
      line-height: 33px;
    }
    .welcome-bar__degrees {
      font-size: 24px;
      line-height: 11px;
    }
    .welcome-bar__farenheit {
      font-size: 14px;
    }
    .welcome-bar__forecast,
    .welcome-bar__period,
    .welcome-bar__pipe,
    .welcome-bar__time-zone {
      font-size: 10px;
    }
  }
}

@media (min-width: 1240px) {
  // welcome bar
  .welcome-bar {
    padding: 36px 48px;
  }
  // info left
  .welcome-bar__info-left {
    padding: 0 48px 0 24px;
  }
  // info middle
  .welcome-bar__info-middle {
    padding: 0 48px;
  }
  .welcome-bar__info-middle-icon {
    width: auto;
  }
  // info right
  .welcome-bar__info-right {
    padding: 0 0 0 48px;
  }

  // welcome bar compressed
  .welcome-bar-compressed {
    padding: 20px 48px;
  }
}
