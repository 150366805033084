/*! footer */
@import "../styles/settings/index.scss";

// footer
.footer {
  background-color: $color-mist;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 24px 24px 80px 24px;
}

// upper
.footer__upper {
  display: flex;
}
.footer__logo {
  width: 287;
  height: 55px;
}

// middle
.footer__middle {
  border-top: 1px solid #c7dbf2;
  border-bottom: 1px solid #c7dbf2;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 20px;
  padding: 12px 0;
}
.footer__link {
  color: $color-navy;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 2px 0;
  width: calc(100% / 2);
  &:hover {
    .footer__link-border {
      border-bottom: 1px solid $color-navy;
    }
  }
}
.footer__link-border {
  border-bottom: 1px solid transparent;
  display: inline-block;
  padding: 1px 0;
  transition: border-bottom 0.15s;
}

// lower
.footer__lower {
  display: flex;
}
.footer__social-media {
  align-items: center;
  display: flex;
}
.footer__social-media--desktop {
  display: none;
}
.footer__social-media--mobile {
  width: 40%;
}
.footer__social-media-link {
  margin-right: 24px;
  opacity: 0.7;
  transition: opacity 0.15s;
  &:hover {
    opacity: 1;
  }
  &:last-child {
    margin-right: 0;
  }
}
.footer__fine-print {
  font-size: 10px;
  line-height: 15px;
  width: 60%;
  &#footer-disclaimer{
    width: 100%;
    margin-top: 0px;
  }
}

.footer__tech-support{
  font-size: 12px;
  margin-bottom: 16px;
}
.footer__tech-support a{
  color: #353535
}

@media (min-width: $breakpoint-mobile) {
  // middle
  .footer__link {
    width: calc(100% / 3);
  }
}

@media (min-width: $breakpoint-mobile-landscape) {
  // lower
  .footer__social-media--mobile {
    flex-shrink: 0;
    width: calc(100% / 3);
  }
  .footer__fine-print {
    width: 100%;
    &#footer-disclaimer{
      max-width: 900px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  body:not(.properties-map-pg) {
    // footer
    .footer {
      padding: 24px 22px 24px 60px;
    }

    // upper
    .footer__upper {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .footer__social-media-link {
      margin-right: 36px;
    }
    .footer__social-media--desktop {
      display: flex;
    }

    // middle
    .footer__middle {
      border: none;
      margin: 0 0 20px;
      padding: 0;
    }

    // lower
    .footer__social-media--mobile {
      display: none;
    }

    .footer__fine-print#footer-disclaimer {
      margin-top: 10px;
    }
  }
}

@media (min-width: 910px) {
  // middle
  .footer__link {
    letter-spacing: 0;
    margin: 0 30px 0 0;
    width: auto;
  }
}

/*@media (min-width: $breakpoint-desktop) {

  .footer {
    padding: 38px 54px 28px;
  }

  // upper
  .footer__social-media-link {
    margin-right: 44px;
  }
}*/

@media (min-width: 992px) and (min-height: 680px) {
  .properties-map-pg{
    // footer
    .footer {
      padding: 24px 22px 24px 60px;
    }

    // upper
    .footer__upper {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .footer__social-media-link {
      margin-right: 36px;
    }
    .footer__social-media--desktop {
      display: flex;
    }

    // middle
    .footer__middle {
      border: none;
      margin: 0 0 20px;
      padding: 0;
    }

    // lower
    .footer__social-media--mobile {
      display: none;
    }

    .footer__fine-print#footer-disclaimer {
      margin-top: 10px;
    }
  }
}

