.fa-ccpa-display-none {
    display: none;
}
.fa-ccpa-notice {
    position: fixed;
    bottom: 20px;
    z-index: 10000;
    margin: 0px 68px;
    padding: 20px;
    background: #323840;
    min-height: 84px;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
  }

  .fa-ccpa-notice p {
    color: #ffffff;
    font-size: 14px;
    float: left;
    display: block;
    font-weight: 400;
    line-height: 21px;
    margin: 10px;
    width: 85%;
    text-align: left;
  }
  
  #fa-ccpa-title {
    font-weight: 700;
  }
  #fa-ccpa-content {
    font-weight: 400;
    
  }

  .fa-ccpa-notice button {
    background: #ffffff;
    color: #1E4C82;
    border: none;
    position: absolute;
    border-radius: 20px;
    width: 147px;
    height: 40px;
    font-family: "Inter", sans-serif;    
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .fa-ccpa-notice__link {
    color: #ffffff;
    text-decoration: underline;
    font-size: 14px;
  }
  
  .fa-ccpa-notice__link:hover,
  .fa-ccpa-notice__link:active {
    text-decoration: none;
    font-size: 14px;
    color: #ffffff;
  }

  #fa-ccpa-button {
    top: 40%;
    right: 3%;
  }


  @media (max-width: 1280px) {
    .fa-ccpa-notice p {
      width: 74%;
    }
    #fa-ccpa-button {
      right: 3%;
    }
  }

  @media (max-width: 800px) {
    .fa-ccpa-notice button {
      position: static;
      margin: 13px 0px 10px 10px;
      width: 139px;
      height: 32px;
    }

    #fa-ccpa-title {
      font-size: 13px;
    }
    #fa-ccpa-content {
      font-size: 13px;
      width:95%;
    }
    .fa-ccpa-notice {
      margin: 15px;
    }
  }