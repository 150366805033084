// main content container
.main {
  display: flex;
  //min-height: auto;
  position: fixed;
  top: 80px;
  //transform: translateY(0);
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
}

// content wrap
.content-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

// app
.app {
  background-color: $color-white;
  box-sizing: border-box;
  flex-grow: 1;
  //padding: 30px 22px;
  padding: 0px;
  width: 100%;
	&.app-nopadding{
	padding:0px;
	}  
}

//.sidebar-compressed .main {
//  padding-left: 70px !important;
//}

@media (min-width: $breakpoint-tablet) {
  // main content container
  body:not(.properties-map-pg) {
    .main {
      //min-height: calc(100vh - 70px);
      top: 70px;
      //transform: translateY(0);
      padding-left: 180px;
      box-sizing: border-box;
      transition: all 0.3s ease-in-out;
      //.sidebar-compressed & {
      //  padding-left: 70px;
      //}
    }

    .sidebar--compressed-main {
      &.main {
        padding-left: 70px;
      }
    }

    // app
    /*.app {
      padding: 0px;
    
    &.app-nopadding{
      padding:0px;
      }
    }*/
  }
}

// Iphone X lanscape mobile view

@media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-desktop-height) {
  body:not(.properties-map-pg) {
    .main {
      padding-left: 0;
    }
  }
}
@media (min-width: 992px) and (min-height: 680px){
  .properties-map-pg {
  // main content container
  
    .main {
      //min-height: calc(100vh - 70px);
      top: 70px;
      //transform: translateY(0);
      padding-left: 180px;
      box-sizing: border-box;
      transition: all 0.3s ease-in-out;
      //.sidebar-compressed & {
      //  padding-left: 70px;
      //}
    }

    .sidebar--compressed-main {
      &.main {
        padding-left: 70px;
      }
    }

    // app
    /*.app {
      padding: 0px;
    
    &.app-nopadding{
      padding:0px;
      }
    }*/
  }
}

@supports (padding-top: env(safe-area-inset-top)) {

  .main {
      top: calc(80px + (env(safe-area-inset-top)/2));
  }
  @media (min-width: 768px) {
      .main {
          top: calc(70px + (env(safe-area-inset-top)/2));
      }
  }
  
}