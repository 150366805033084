// avenir
@font-face {
  font-family: "Avenir";
  src: url("/_CDN/assets/common/fonts/avenir/AvenirLTStd-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("/_CDN/assets/common/fonts/avenir/AvenirLTStd-Roman.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("/_CDN/assets/common/fonts/avenir/AvenirLTStd-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

// inter
@font-face {
  font-family: "Inter";
  src: url("/_CDN/assets/common/fonts/inter/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/_CDN/assets/common/fonts/inter/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/_CDN/assets/common/fonts/inter/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

// font variables
$font-avenir: "Avenir",
Arial,
sans-serif;
$font-inter: "Inter",
Arial,
sans-serif;