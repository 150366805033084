body {
  color: $color-dark-gray;
  font: 400 14px/17px $font-inter;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
.h1 {
  font-family: $font-avenir;
  font-size: 35px;
  letter-spacing: -0.55px;
  line-height: 39px;
}

h2,
.h2 {
  font-family: $font-avenir;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
}

h3,
.h3 {
  font-family: $font-avenir;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
}

h4,
.h4 {
  font-family: $font-inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

h5,
.h5 {
  font-family: $font-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

h6,
.h6 {
  font-family: $font-inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

p {
  margin: 0;
}

label {
  font-weight: 500;
}

.caption {
  font-size: 10px;
  line-height: 13px;
}

.numbers {
  font-family: $font-inter;
  font-weight: 400;
  margin-bottom: 40px;
}

.numbers--small {
  font-size: 40px;
}

.numbers--medium {
  font-size: 50px;
}

.numbers--large {
  font-size: 65px;
}

.typography-nav {
  color: $color-navy;
}