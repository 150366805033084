// feature
.feature-wrap {
  display: flex;
  flex-wrap: wrap;
}
.feature {
  background-color: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 280px;
  justify-content: space-between;
  margin: 0 40px 40px 0;
  max-width: 315px;
  padding: 24px;
  width: 100%;
}
.feature--earth {
  background-color: $color-earth;
}
.feature--mint {
  background-color: $color-mint;
}
.feature--starlite {
  background-color: $color-starlite;
}
// top
.feature__top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.feature__header {
  font-weight: 500;
  line-height: 20px;
  margin-right: 24px;
}
.feature__header-icon {
  opacity: 0.5;
  width: 24px;
}
// middle
.feature__middle-text {
  line-height: 20px;
}
// bottom
.feature__bottom-link {
  align-items: center;
  color: $color-navy;
  display: flex;
  justify-content: space-between;
  &:hover {
    .feature__bottom-icon {
      transform: rotate(90deg) translateY(-5px);
    }
  }
  + .feature__bottom-link {
    margin-top: 12px;
  }
}
.feature__bottom-icon {
  height: 20px;
  transform: rotate(90deg) translateY(0);
  transition: transform 0.2s ease-in-out;
  width: 18px;
}

// feature with image
.feature-with-image {
  display: flex;
  flex-direction: column;
  width: 335px;
  // feature
  .feature {
    border-radius: 0 0 8px 8px;
    margin: 0;
    max-width: none;
    width: 100%;
  }
}
.feature-with-image__img {
  border-radius: 8px 8px 0 0;
  width: 100%;
}

@media (min-width: $breakpoint-tablet) {
  // feature
  .feature {
    height: 240px;
  }
}

@media (min-width: $breakpoint-tablet-landscape) {
  // feature with image
  .feature-with-image {
    flex-direction: row;
    width: 670px;
    // feature
    .feature {
      border-radius: 0 8px 8px 0;
      width: 50%;
    }
  }
  .feature-with-image__img {
    border-radius: 8px 0 0 8px;
    width: 50%;
  }
}

@media (min-width: $breakpoint-desktop) {
  // feature
  .feature {
    margin-bottom: 0;
  }
}
