        // @keyframes slideInTop {
        //     from{
        //         transform: translateY(-100px)
        //     }
        //     to{
        //         transform: translateY(0);
        //     }
        // }
        #igre-connectivity-banner{
            height: 36px;
            width: 100%;
            text-align: center;
            line-height: 21px;
            background-color: #E24545;
            color: #ffffff;
            // animation: slideInTop 1s ease-in forwards;
            // transform: translateY(-150px);
            position: relative;
            left:0;
            right:0;
            top: 0;
            z-index: 700;
            //margin-top: -36px;
            //padding-top: 7px;
            display: none;
        }
        #igre-connectivity-banner span{
            font-weight: 500;
            font-size: 14px;
            padding-left: 12px;
        }
        #igre-connectivity-banner div{
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            max-width: 320px;
            margin: auto;
            height: 36px
        }        