/*! sidebaritem */
@import "../styles/settings/index.scss";

// subnav
.subnav {
  background-color: $color-dark-gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  list-style: none;
  opacity: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-85vw);
  transition: all 0.4s ease-in-out;
  visibility: hidden;
  width: 85vw;
  z-index: 9999;
}

@media (max-width: 767px) {
  body:not(.properties-map-pg) {
    .subnav {
      //top: -80px !important;
      top: 0 !important;
      position: fixed;
      bottom: 0;
      overflow-y: auto;
    }
  }
}

// open
.sidebar__list-item--open {
  .subnav {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
// active
.sidebar__list-item--active {
  .subnav__list-link {
    font-weight: 400;
  }
}
// compressed
.sidebar--compressed {
  .subnav {
    transform: translate(47px, -47px);
  }
  .sidebar__list-item--open {
    .subnav {
      transform: translate(57px, -47px);
    }
  }
}

// mobile header
.subnav__mobile-header-wrap {
  cursor: pointer;
  align-items: center;
  display: flex;
  margin: 22px;
  > svg {
    transform: translateY(-2px);
  }
}
.subnav__mobile-header {
  font: 500 20px $font-avenir;
  margin-left: 17px;
}

// list item
.subnav__list-item {
  border-bottom: 1px solid #242424;
  display: flex;
  &:last-child {
    border-bottom: none;
  }
}
.subnav__list-link {
  align-items: center;
  color: $color-white;
  display: flex;
  height: 48px;
  opacity: 0.75;
  padding: 0 22px;
  text-decoration: none;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}

.sidebar__link-icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
}
.sidebarItems__dashboard-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M4.5 3H9a1.5 1.5 0 0 1 1.5 1.5V9A1.5 1.5 0 0 1 9 10.5H4.5A1.5 1.5 0 0 1 3 9V4.5A1.5 1.5 0 0 1 4.5 3Zm0 6H9V4.5H4.5V9ZM15 3h4.5A1.5 1.5 0 0 1 21 4.5V9a1.5 1.5 0 0 1-1.5 1.5H15A1.5 1.5 0 0 1 13.5 9V4.5A1.5 1.5 0 0 1 15 3Zm0 6h4.5V4.5H15V9Zm-6 4.5H4.5A1.5 1.5 0 0 0 3 15v4.5A1.5 1.5 0 0 0 4.5 21H9a1.5 1.5 0 0 0 1.5-1.5V15A1.5 1.5 0 0 0 9 13.5Zm0 6H4.5V15H9v4.5Zm6-6h4.5A1.5 1.5 0 0 1 21 15v4.5a1.5 1.5 0 0 1-1.5 1.5H15a1.5 1.5 0 0 1-1.5-1.5V15a1.5 1.5 0 0 1 1.5-1.5Zm0 6h4.5V15H15v4.5Z' clip-rule=' evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__transactions-icon {
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M7.368 20.03 12 22.5l4.632-2.47A8.237 8.237 0 0 0 21 12.75V3a1.502 1.502 0 0 0-1.5-1.5h-15A1.502 1.502 0 0 0 3 3v9.75a8.236 8.236 0 0 0 4.368 7.28ZM4.5 12.75V3h15v9.75a6.739 6.739 0 0 1-3.574 5.957L12 20.8l-3.926-2.093A6.739 6.739 0 0 1 4.5 12.75Zm4.057-2.25 1.943 1.943L15.443 7.5 16.5 8.557l-6 6-3-3L8.557 10.5Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__properties-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath d='M12 13.5A3.75 3.75 0 1 1 12 6a3.75 3.75 0 0 1 0 7.5Zm0-6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z'/%3E%3Cpath d='m12 22.5-6.327-7.461a26.199 26.199 0 0 1-.26-.34A8.167 8.167 0 0 1 3.75 9.75a8.25 8.25 0 1 1 16.5 0 8.163 8.163 0 0 1-1.661 4.948l-.001.002s-.225.296-.259.335L12 22.5Zm-5.39-8.704s.174.231.214.281L12 20.181l5.183-6.113.209-.274A6.676 6.676 0 0 0 18.75 9.75a6.75 6.75 0 0 0-13.5 0 6.68 6.68 0 0 0 1.36 4.046Z'/%3E%3C/svg%3E");
}
.sidebarItems__marketing-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M3 3.75h18a1.501 1.501 0 0 1 1.5 1.5v13.5a1.502 1.502 0 0 1-1.5 1.5H3a1.502 1.502 0 0 1-1.5-1.5V5.25A1.502 1.502 0 0 1 3 3.75Zm8.25 1.5H3v13.5h8.25V5.25Zm1.5 0v13.5H21V5.25h-8.25ZM6 7.5h3.75v5.25H4.5V7.5H6Zm2.25 3.75V9H6v2.25h2.25ZM19.5 7.5h-5.25V9h5.25V7.5Zm0 3.75h-5.25v1.5h5.25v-1.5ZM14.25 15h5.25v1.5h-5.25V15Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__calculators-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.25 2.25H3.75a1.502 1.502 0 0 0-1.5 1.5v16.5a1.502 1.502 0 0 0 1.5 1.5h16.5a1.502 1.502 0 0 0 1.5-1.5V3.75a1.502 1.502 0 0 0-1.5-1.5Zm-9 1.5v7.5h-7.5v-7.5h7.5Zm-7.5 9h7.5v7.5h-7.5v-7.5Zm9 7.5V3.75h7.5v16.5h-7.5Zm1.5-10.5h4.5v1.5h-4.5v-1.5Zm0 3h4.5v1.5h-4.5v-1.5Zm-4.5-6h-4.5v1.5h4.5v-1.5Zm0 9h-1.5v-1.5h-1.5v1.5h-1.5v1.5h1.5v1.5h1.5v-1.5h1.5v-1.5Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__classes-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath d='M13.5 19.5h-11v-15h17v3H21v-3A1.5 1.5 0 0 0 19.5 3h-17A1.5 1.5 0 0 0 1 4.5v15A1.5 1.5 0 0 0 2.5 21h11v-1.5Z'/%3E%3Cpath d='M4 7h8.5v1.5H4V7ZM4 11h6.5v1.5H4V11Z'/%3E%3Cpath fill-rule='evenodd' d='M22.458 11.349a4.5 4.5 0 0 1-.958 5.496V22.5l-3-1.42-3 1.42v-5.655a4.5 4.5 0 1 1 6.958-5.496Zm-3.958 8.07 1.5.71v-2.392c-.97.35-2.03.35-3 0v2.393l1.5-.71Zm-1.667-3.425a3 3 0 1 0 3.333-4.987 3 3 0 0 0-3.333 4.987Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__farming-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M18 9.75A8.142 8.142 0 0 1 16.086 15l5.664 5.69-1.06 1.06L15 16.086A8.142 8.142 0 0 1 9.75 18 8.25 8.25 0 1 1 18 9.75ZM6 15.362A6.75 6.75 0 0 0 9.75 16.5a6.758 6.758 0 0 0 6.75-6.75A6.75 6.75 0 1 0 6 15.362ZM10.5 9h3v1.5h-3v3H9v-3H6V9h3V6h1.5v3Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
.sidebarItems__eaglestatus-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M6 21h5.25v1.5H6A1.504 1.504 0 0 1 4.5 21V3A1.504 1.504 0 0 1 6 1.5h7.5a.681.681 0 0 1 .525.225l5.25 5.25a.682.682 0 0 1 .225.525v6H18V9h-4.5A1.504 1.504 0 0 1 12 7.5V3H6v18ZM17.7 7.5l-4.2-4.2v4.2h4.2Zm-3.143 10.942 1.943 1.943 4.942-4.943L22.5 16.5l-6 6-3-3 1.057-1.058Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

@media (min-width: 280px) {
  .subnav{
    width: 65vw;
  }
}
@media (min-width: $breakpoint-mobile){
  .subnav {
    transform: translateX(-55vw);
    width: 55vw;
  }
}
@media (min-width: $breakpoint-mobile-landscape) {
  // subnav
  .subnav {
    transform: translateX(-65vw);
    width: 65vw;
  }
}


@media (min-width: 600px) {
  // subnav
  .subnav {
    transform: translateX(-250px);
    width: 250px;
  }
}

@media (min-width: $breakpoint-tablet) {
  body:not(.properties-map-pg) {
    // subnav
    .subnav {
      height: auto;
      padding: 16px;
      transform: translate(144px, -47px);
      transition: all 0.25s ease-in-out;
      width: 175px;
    }
    // open
    .sidebar__list-item--open {
      .subnav {
        transform: translate(154px, -47px);
      }
    }

    // mobile header
    .subnav__mobile-header-wrap {
      display: none;
    }
  }
}

// Iphone X lanscape mobile view

@media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-desktop-height) {
  body:not(.properties-map-pg) {
    .subnav {
      //top: -80px !important;
      top: 0 !important;
      position: fixed;
      bottom: 0;
      transform: translateX(-250px);
      width: 250px;
      padding: 0;
      overflow-y: auto;
    }
    .sidebar__list-item--open .subnav {
      transform: translateX(0);
    }
    .subnav__mobile-header-wrap {
        display: flex;
    }
  }
}

@media (min-width: 992px) and (min-height: 680px) {
  .properties-map-pg {
    // subnav
    .subnav {
      height: auto;
      padding: 16px;
      transform: translate(144px, -47px);
      transition: all 0.25s ease-in-out;
      width: 175px;
    }
    // open
    .sidebar__list-item--open {
      .subnav {
        transform: translate(154px, -47px);
      }
    }

    // mobile header
    .subnav__mobile-header-wrap {
      display: none;
    }
  }
}

@media (max-width: 991px), (max-height: 680px) {
  .properties-map-pg {
    .subnav {
      //top: -80px !important;
      top: 0 !important;
      position: fixed;
      bottom: 0;
      overflow-y: auto;
    }
  }
}