/*! sidebar */
@import "../styles/settings/index.scss";

// sidebar

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  transition: opacity .15s linear;
  background-color: rgba(30, 76, 130, 0.65);
  visibility: hidden;
  .backdrop-show &{
    z-index: 400;
    opacity: .5;
    visibility: visible;
  }
}

.sidebar {
  background-image: none;
  background-color: $color-fa-blue;
  transform: translateX(-85vw);
  transition: all 0.4s ease-in-out;
  width: 85vw;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 500;
  display: flex;
  flex: 0 0 85vw;
  flex-direction: column;
  &.is-visible {
    transform: translateX(0);
  }
}


.sidebar__wrap {
  display: flex;
  flex-direction: column;
}

.sidebar__top{
  flex: 1 0 auto;
}
.sidebar__bottom, .sidebar-bottom-mobile{
  flex-shrink: 0;
}

.sidebar__link-name {
  display: flex;
  opacity: 0.8;
  transition: all 0.2s;
  visibility: visible;
}

.sidebar__logo {
  display: none;
}

.sidebar__bottom {
  display: none;
}

.sidebar--compressed {
  .sidebar__list{
    .sidebar__list-item{
      &.sidebar__list-item--open {
        .sidebar__caret {
          transform: rotate(45deg) translate(-4px, -7px);
        }
      }
    }
  }
}


// list
.sidebar__list {
  margin: 34px 0 0;
  padding: 0;
  .sidebar__list-item {
    list-style: none;
    margin-bottom: 16px;
    a.sidebar__list-link, div.sidebar__list-link {
      align-items: center;
      border-left: 5px solid transparent;
      color: $color-white;
      cursor: pointer;
      display: flex;
      height: 42px;
      text-decoration: none;
      .sidebar__link-icon {
        opacity: 0.5;
        transition: opacity 0.2s;
      }
      &:hover {
        color: $color-white;
        text-decoration: none;
        .sidebar__link-name {
          opacity: 1;
          color: $color-white;
          text-decoration: none;
        }
        .sidebar__link-icon {
          opacity: 1;
        }
      }
    }
    &.sidebar__list-item--open {
      .sidebar__caret {
        opacity: 1;
        transform: rotate(45deg) translate(-15px, 3px);
      }
    }
    &.sidebar__list-item--active {
      margin-bottom: 16px;
      .sidebar__list-link {
        border-color: $color-sunshine;
        font-weight: 600;
        .sidebar__link-icon {
          opacity: 1;
        }
        .sidebar__link-name {
          opacity: 1;
        }
      }
    }
  }
}

.sidebar__caret {
  background-color: $color-dark-gray;
  display: none;
  height: 16px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 21px;
  transform: rotate(45deg) translate(-20px, 8px);
  transition: opacity 0.25s, transform 0.25s;
  width: 16px;
}

.sidebar__link-icon-wrap {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin: 0 18px 0 17px;
  width: 24px;
}



// rep
.sidebar__rep {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  padding: 0 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.sidebar__rep-info {
  max-height: 300px;
  opacity: 1;
  transition: max-height 0.6s ease-in-out, opacity 0.2s ease-in-out,
  visibility 0.2s ease-in-out;
  visibility: visible;
  width: 140px;
}

.sidebar__rep-img {
  height: 52px;
  margin-bottom: 10px;
  width: 52px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  .sidebar--compressed &{
    height: 42px;
    width: 42px;
  }
}

.sidebar__rep-name {
  color: $color-white;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}

.sidebar__rep-role {
  color: #7993b4;
  font-size: 13px;
  font-weight: 500;
}

// resize
.sidebar__resize {
  align-items: center;
  background-color: $color-deep-sapphire;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
}

.sidebar__resize-arrow {
  transform: rotate(0);
}

// mobile nav
.sidebar-bottom-mobile__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 5px 22px 0;
  padding: 0px 0px 20px 0px;
}

.sidebar-bottom-mobile__list-item {
  margin: 9px 0;
}

a.sidebar-bottom-mobile__link, div.sidebar-bottom-mobile__link {
  align-items: center;
  color: $color-white;
  display: flex;
  text-decoration: none;
  &:hover {
    color: $color-white;
    text-decoration: none;
    .sidebar-bottom-mobile__link-icon {
      opacity: 1;
    }

    .sidebar-bottom-mobile__link-title {
      opacity: 1;
    }
  }
}

.sidebar-bottom-mobile__link-icon {
  height: 21px;
  opacity: 0.5;
  transition: opacity 0.2s;
  width: 21px;
}

.sidebar-bottom-mobile__link-title {
  cursor: pointer;
  margin-left: 18px;
  opacity: 0.8;
  transition: opacity 0.2s;
}


@media (min-width: $breakpoint-mobile-landscape){

  // sidebar
  .sidebar {
    transform: translateX(-65vw);
    width: 65vw;
    flex: 0 0 65vw;
  }
}

@media (min-width: 280px) {

  // sidebar
  .sidebar {
    transform: translateX(-65vw);
    width: 65vw;
    top: 80px;
    flex: 0 0 65vw;
    &.no-scroll{
      overflow: hidden;
    }
  }
  .sidebar__wrap {
    overflow-y: auto;
    //height: 78vh;
    height: calc(100% - 80px);
  }
}

@media (max-width: 767px) {
  body:not(.properties-map-pg) {
    .sidebar {
      overflow-y: auto;
    }
  }
}
@media (min-width: $breakpoint-mobile) {

  // sidebar
  .sidebar {
    transform: translateX(-55vw);
    width: 55vw;
    flex: 0 0 55vw;
  }
}

@media (min-width: 600px) {

  // sidebar
  .sidebar {
    transform: translateX(-250px);
    width: 250px;
    flex: 0 0 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}



@media (min-width: $breakpoint-tablet) {
  //#sidebar_dashboard_id {
  //  display:none
  //}

  body:not(.properties-map-pg) {
    // sidebar
    .backdrop-show .backdrop{
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
    .sidebar {
      background: $color-fa-blue url("/_CDN/assets/global-nav/logo-large-occluded-tm.svg") -8px -34px no-repeat;
      background-size: 228px;
      // height: calc(100vh - 70px);
      // position: relative;
      transform: translateX(0);
      width: 180px;
      padding: 155px 0 0;
      flex: 0 0 180px;
      top: 0;
      overflow: hidden;
      &.no-scroll{
        overflow:visible;
      }
    }
    .sidebar__wrap{
      overflow-y: inherit;
      height: calc(100% - 30px);
    }

    .sidebar__logo {
      left: 0;
      position: absolute;
      top: -80px;
      transform: scale(1) translate(0, 15px);
      transition: transform 0.4s ease-in-out;
      width: 227px;
    }

    .sidebar__bottom {
      display: block;
    }

    .sidebar__caret {
      display: block;
    }

    // compressed
    .sidebar--compressed {
      width: 70px;
      flex: 0 0 70px;
      &.sidebar {
        background-position: -50px -27px;
        background-size: 181px;
      }
      .sidebar__logo {
        transform: scale(0.8) translate(-91px, -5px);
      }
      .sidebar__caret {
        transform: rotate(45deg) translate(-10px, -2px);
      }
      .sidebar__link-name {
        opacity: 0;
        visibility: hidden;
      }
      .sidebar__resize-arrow {
        transform: rotate(180deg);
      }
      .sidebar__rep {
        margin-bottom: 18px;
        padding: 0;
      }
      .sidebar__rep-info {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
    // list
    .sidebar__list {
      margin: 50px 0 0;
    }

    .sidebar__list-link {
      position: relative;
    }

    // mobile nav
    .sidebar-bottom-mobile {
      display: none;
    }
  }
}
@media (min-width: $breakpoint-desktop) {
  .sidebar__wrap{
    height: 100%;
  }
}

// Iphone X lanscape mobile view

@media (min-width: $breakpoint-tablet) and (max-height: $breakpoint-desktop-height) {

  //#sidebar_dashboard_id {
  //  display:block;
  //}

  body:not(.properties-map-pg) {
    // sidebar
    .sidebar {
      background-image: none;
      transform: translateX(-55vw);
      width: 250px;
      top: 80px;
      flex: 0 0 250px;
      overflow-y: auto;
      padding: 0;
      &.no-scroll{
        overflow: hidden;
      }
      &.is-visible {
        transform: translateX(0);
      }
    }
    .sidebar__caret {
      display: none;
    }
    .backdrop-show .backdrop {
      z-index: 400;
      opacity: .5;
      visibility: visible;
    }
    .sidebar__bottom {
      display: none;
    }
    .sidebar-bottom-mobile {
        display: block;
    }
  }
}

@media (min-width: 992px) and (min-height: 680px) {
  .properties-map-pg{
    // sidebar
    .backdrop-show .backdrop{
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
    .sidebar {
      background: $color-fa-blue url("/_CDN/assets/global-nav/logo-large-occluded-tm.svg") -8px -34px no-repeat;
      background-size: 228px;
      // height: calc(100vh - 70px);
      // position: relative;
      transform: translateX(0);
      width: 180px;
      padding: 155px 0 0;
      flex: 0 0 180px;
      top: 0;
      overflow: hidden;
      &.no-scroll{
        overflow:visible;
      }
    }
    .sidebar__wrap{
      overflow-y: inherit;
      height: calc(100% - 30px);
    }

    .sidebar__logo {
      left: 0;
      position: absolute;
      top: -80px;
      transform: scale(1) translate(0, 15px);
      transition: transform 0.4s ease-in-out;
      width: 227px;
    }

    .sidebar__bottom {
      display: block;
    }

    .sidebar__caret {
      display: block;
    }

    // compressed
    .sidebar--compressed {
      width: 70px;
      flex: 0 0 70px;
      &.sidebar {
        background-position: -50px -27px;
        background-size: 181px;
      }
      .sidebar__logo {
        transform: scale(0.8) translate(-91px, -5px);
      }
      .sidebar__caret {
        transform: rotate(45deg) translate(-10px, -2px);
      }
      .sidebar__link-name {
        opacity: 0;
        visibility: hidden;
      }
      .sidebar__resize-arrow {
        transform: rotate(180deg);
      }
      .sidebar__rep {
        margin-bottom: 18px;
        padding: 0;
      }
      .sidebar__rep-info {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
    // list
    .sidebar__list {
      margin: 50px 0 0;
    }

    .sidebar__list-link {
      position: relative;
    }

    // mobile nav
    .sidebar-bottom-mobile {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .properties-map-pg{
    .sidebar {
      overflow-y: auto;
    }
  }
}