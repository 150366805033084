// buttons
.button {
  background-color: transparent;
  border-radius: 20px;
  color: $color-medium-blue;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 11px 30px;
  text-align: center;
}
.button--dark {
  background-color: $color-navy;
  color: $color-white;
}
.button--light {
  background-color: $color-white;
  border: 1px solid $color-medium-blue;
  color: $color-medium-blue;
}
.button--small {
  background-color: #c7dbf2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 30px;
  &:hover {
    .button__icon {
      transform: rotate(90deg) translate(1px, -5px);
    }
  }
}
.button--transparent {
  background-color: transparent;
  border-bottom: 1px solid;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 3px 0;
}
.button__icon {
  height: 14px;
  margin-left: 8px;
  transform: rotate(90deg) translate(1px, 0);
  transition: transform 0.2s ease-in-out;
  width: 10px;
}
