// promo
.promo {
  background-color: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 315px;
}

.promo--mint {
  background-color: $color-mint;
}

.promo--lilac {
  background-color: $color-lilac;
}

// top
.promo__top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.promo__header {
  font-weight: 500;
  line-height: 20px;
  margin-right: 24px;
}

.promo__header-icon {
  opacity: 0.5;
  width: 24px;
}

// promo large
.promo-large {
  display: flex;
  flex-direction: column;
  width: 335px;

  // promo
  .promo {
    border-radius: 0 0 8px 8px;
    width: 100%;
  }

  .promo-large__img {
    border-radius: 8px 8px 0 0;
  }

  .promo__header-wrap {
    display: flex;
    flex-direction: column;
  }

  .promo__superheader {
    color: $color-electric-blue;
    font-weight: 400;
    line-height: 15px;
    margin: 0 24px 10px 0;
  }

  // middle
  .promo__middle {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-bottom: 15px;
  }

  .promo__date-wrap {
    align-items: center;
    background-color: rgba(192, 200, 209, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    padding: 6px 10px;
  }

  .promo__date-month {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 16px;
  }

  .promo__date-day {
    color: $color-electric-blue;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.15px;
    line-height: 20px;
  }

  .promo__details-wrap {
    flex-grow: 1;
  }

  .promo__details-location,
  .promo__details-time {
    font-size: 12px;
    line-height: 19px;
  }

  .promo__price {
    color: $color-navy;
    font-weight: 500;
  }

  // lower wrap
  .promo__lower-wrap {
    background-color: $color-white;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 24px 15px;
  }

  .promo__lower-wrap--mist {
    background-color: $color-mist;
  }

  // bottom
  .promo__bottom {
    border-top: 1px solid #c7dbf2;
    padding-top: 12px;
  }

  .promo__bottom-link {
    align-items: center;
    color: $color-navy;
    display: flex;
    justify-content: space-between;

    &:hover {
      .promo__bottom-icon {
        transform: rotate(90deg) translateY(-5px);
      }
    }
  }

  .promo__bottom-icon {
    height: 20px;
    transform: rotate(90deg) translateY(0);
    transition: transform 0.2s ease-in-out;
    width: 18px;
  }
}

@media (min-width: $breakpoint-tablet-landscape) {

  // promo large
  .promo-large {
    flex-direction: row;
    width: 670px;

    // promo
    .promo {
      border-radius: 0 8px 8px 0;
      width: 50%;
    }

    .promo-large__img {
      border-radius: 8px 0 0 8px;
      width: 50%;
    }
  }
}