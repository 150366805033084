.color--light {
  color: $color-white;
}
.color--dark {
  color: $color-black;
}
.color__name {
  flex-grow: 1;
}
.color__details {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.color--dark-gray {
  background-color: $color-dark-gray;
}
.color--fa-blue {
  background-color: $color-fa-blue;
}
.color--navy {
  background-color: $color-navy;
}
.color--sunshine {
  background-color: $color-sunshine;
}
.color--sky {
  background-color: $color-sky;
}
.color--forest {
  background-color: $color-forest;
}
.color--electric-blue {
  background-color: $color-electric-blue;
}
.color--lavender {
  background-color: $color-lavender;
}
.color--paprika {
  background-color: $color-paprika;
}
.color--light-gray {
  background-color: $color-light-gray;
}
.color--mint {
  background-color: $color-mint;
}
.color--peach {
  background-color: $color-peach;
}
.color--mist {
  background-color: $color-mist;
}
.color--earth {
  background-color: $color-earth;
}
.color--lilac {
  background-color: $color-lilac;
}
.color--starlite {
  background-color: $color-starlite;
}
.color--medium-blue {
  background-color: $color-medium-blue;
}
