// COMPONENT LIBRARY STYLES
//////////////////////////////////
// This file includes all base styles (including for CL components), plus the styles required to render the CL properly.
// The styles specific to the global-nav are collocated with the components they style, elsewhere in the JS app.

// component
.component {
  margin-bottom: 60px;
}

.component__header-wrap {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}

.component__header {
  font-weight: 400;
}

.component__label {
  align-items: center;
  background-color: $color-earth;
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  height: 19px;
  justify-content: center;
  margin: 0 0 0 16px;
  padding: 0 8px;
}

// components
.components__header {
  border-top: 1px solid #ccc;
  font-weight: 500;
  margin: 32px 0;
  padding: 24px 0;
}

// colors
.colors {
  display: grid;
  grid-gap: 16px 16px;
  grid-template-columns: repeat(5, 1fr [col-start]);
  margin-bottom: 32px;
}

.colors--pastels {
  grid-template-columns: repeat(3, 1fr [col-start]);
}

.colors--accents {
  grid-template-columns: repeat(4, 1fr [col-start]);
}

// color
.color {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 120px;
  padding: 20px;
}

// typography
.typography {
  display: flex;
  margin-bottom: 40px;
}

.typography-wrap {
  display: flex;
  flex-direction: column;
}

.typography__example {
  display: flex;
  flex-direction: column;
}

.typography__detail {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 12px;
  margin-right: 40px;
  text-align: right;
  width: 160px;
}

.typography__detail-header {
  margin-bottom: 8px;
  font-weight: 600;
}

#parent-container #feedback-button {
  display: none;
}
#feedback-button-2{
  display: none;
  position: absolute;
  bottom: 28px;
  right: 30px;
  border-radius: 50px;
  padding: 12px 12px;
  font-size: 14px;
  background: #1e4c82;
  color: #1e4d8200;
  box-shadow: 0 1px 13px 0 rgba(99, 129, 163, .8);
  border: 0px none;
  border-image-width: 0px;
  width: 48px; height: 48px;
  background: #1e4c82 url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.672 9.505v-.121c.013-2.372.716-3.094 1.905-3.823.76-.473 1.355-1.144 1.355-2.097 0-1.13-.882-1.853-1.969-1.853-.978 0-1.943.594-2.02 1.95H0C.083 1.258 1.809 0 3.963 0 6.31 0 7.881 1.406 7.881 3.484c0 1.438-.703 2.41-1.847 3.1-1.087.671-1.508 1.33-1.534 2.8v.121H2.672zm.965 3.886a1.278 1.278 0 01-1.278-1.278 1.28 1.28 0 011.278-1.272c.697 0 1.278.575 1.278 1.272 0 .71-.581 1.278-1.278 1.278z' fill='%23ffffff' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat;
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  outline: 0px none;
  &:hover {
    background: #1e4c82 url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.672 9.505v-.121c.013-2.372.716-3.094 1.905-3.823.76-.473 1.355-1.144 1.355-2.097 0-1.13-.882-1.853-1.969-1.853-.978 0-1.943.594-2.02 1.95H0C.083 1.258 1.809 0 3.963 0 6.31 0 7.881 1.406 7.881 3.484c0 1.438-.703 2.41-1.847 3.1-1.087.671-1.508 1.33-1.534 2.8v.121H2.672zm.965 3.886a1.278 1.278 0 01-1.278-1.278 1.28 1.28 0 011.278-1.272c.697 0 1.278.575 1.278 1.272 0 .71-.581 1.278-1.278 1.278z' fill='%23ffffff' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat;
    color: #1e4d8200;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center;
  }
  &:focus {
    outline: 0px none;
  }
}