// primary palette
$color-white: #fff;
$color-black: #000;
$color-dark-gray: #323840;
$color-fa-blue: #1e4c82;
$color-medium-blue: #1e61ac;
$color-navy: #043b7c;
$color-sunshine: #ffd963;
$color-sky: #e6f1ff;

// secondary palette
$color-forest: #509241;
$color-electric-blue: #0044e9;
$color-deep-sapphire: #123979;
$color-lavender: #9d8cda;
$color-paprika: #9d3b3b;
$color-light-gray: #c0c8d1;

// pastels palette
$color-mint: #e9faea;
$color-peach: #f8e8de;
$color-mist: #f7f9fb;
$color-earth: #ebebeb;
$color-lilac: #ede9fa;
$color-starlite: #f8f2de;
