// news
.news {
  background-color: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 240px;
  justify-content: space-between;
  max-width: 315px;
  padding: 24px;
  width: 100%;
}
.news--mist {
  background-color: $color-mist;
}
// top
.news__top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.news__header {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 24px;
}
.news__header-icon {
  opacity: 0.5;
  width: 24px;
}
// middle
.news__middle-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
// bottom
.news__bottom-link {
  align-items: center;
  color: $color-navy;
  display: flex;
  justify-content: space-between;
  &:hover {
    .news__bottom-icon {
      transform: translateX(5px);
    }
  }
}
.news__bottom-icon {
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
  width: 16px;
}
