// data
.data {
  background-color: $color-white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 240px;
  justify-content: space-between;
  margin: 0 40px 40px 0;
  max-width: 315px;
  padding: 24px;
  width: 100%;
}
.data--mint {
  background-color: $color-mint;
}
.data--sky {
  background-color: $color-sky;
}
.data__wrap {
  display: flex;
  flex-wrap: wrap;
}
// top
.data__top {
  display: flex;
  flex-direction: column;
}
.data__header {
  display: flex;
  font-size: 65px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 72px;
  margin-bottom: 4px;
}
.data__header-symbol {
  font-size: 40px;
  margin-left: 4px;
  transform: translateY(-10px);
}
.data__subheader {
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.data__description {
  font-size: 12px;
  line-height: 15px;
}
// bottom
.data__bottom-link {
  align-items: center;
  color: $color-navy;
  display: flex;
  justify-content: space-between;
  &:hover {
    .data__bottom-icon {
      transform: rotate(90deg) translateY(-5px);
    }
  }
}
.data__bottom-icon {
  height: 20px;
  transform: rotate(90deg) translateY(0);
  transition: transform 0.2s ease-in-out;
  width: 18px;
}
